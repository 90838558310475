import 'fontsource-inter';
import 'fontsource-inter/400.css';
import 'fontsource-inter/500.css';
import 'fontsource-inter/600.css';
import 'fontsource-inter/700.css';
import 'fontsource-inter/800.css';
import 'fontsource-noto-serif';
import 'fontsource-noto-serif/400.css';
import 'fontsource-noto-serif/700.css';
import 'keen-slider/keen-slider.min.css';
import './plyr.css';

import { createGlobalStyle } from 'styled-components';

import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

import { colorsV2 } from './colors-v2';

import '../fonts/font-circular.css';

const GlobalStyle = createGlobalStyle`
  :root {
    --plyr-color-main: ${colorsV2.primary100};
  }

  html {
    scroll-behavior: smooth;
  }
  html,
  body {
    width: 100%;
    height: 100%;
    font-family: 'Inter';
  }

  body {
    margin: 0;
    padding: 0;
    color: #232323;
    line-height: 1.5;
    overflow-x: hidden;

    img, p {
      margin: 0;
    }

    a{
      text-decoration:none;
    }

    &::after {
      background-color: transparent;
      transition: background-color 0.5s;
    }

    &.mobile-menu-opened {
      overflow: hidden;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(196,196,196,0.3);
      }
    }

    /* Disable scroll when modal is open */
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  button {
    font-family: 'Inter';
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .pagination {
    list-style-type: none;
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    li {
      &.page > a, button {
        cursor: pointer;
        transition: all .2s ease;
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;
        &:active {
          transform: scale(0.9);
        }
      }
      button {
        background-color: transparent;
        border: none;
      }

      &.page  {
        > a {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          font-size: 14px;
          line-height: 20px;
          background: ${colorsV2.gray40};
          color: ${colorsV2.black100};
        }
        &.active {
          > a {
            background: ${colorsV2.primary100};
            color: ${colorsV2.white100};
          }
        }
      }
    }
  }

  .desktop-only,
  .mobile-only,
  .tablet-only {
    display: none !important;
  }

  @media ${deviceBreakpoints.mobile} {
    .mobile-only {
      display: block !important;
      &.d-flex {
        display: flex !important;
      }
      &.d-inline {
        display: inline !important;
      }
    }
  }
  @media ${deviceBreakpoints.tablet} {
    .tablet-only {
      display: block !important;
      &.d-flex {
        display: flex !important;
      }
      &.d-inline {
        display: inline !important;
      }
    }
  }
  @media ${deviceBreakpoints.desktop} {
    .desktop-only {
      display: block !important;
      &.d-flex {
        display: flex !important;
      }
      &.d-inline {
        display: inline !important;
      }
    }
  }

  .font-primary {
    font-family: 'Inter'
  }
  .font-secondary {
    font-family: 'Noto Serif'
  }
`;

export default GlobalStyle;
